import React from 'react'
import styled from 'styled-components'

export const HollowButton = (props) => {

  const BaseBtn = styled.button`
    color: var(--site-clr-accent);
    background-color: transparent;
    border: 1px solid var(--site-clr-accent);
    padding: ${props.paddingTopBottom || 7}px ${props.paddingLeftRight || 50}px;
    border-radius: 4px; 
    outline: 0;
    font-family: 'Roboto', Arial;
    text-transform: uppercase;
    font-size: clamp(0.8rem, 0.8vw, 2rem);
    cursor: pointer;
    transition: ease background-color 500ms;
  `

  const HollowBtn = styled(BaseBtn)`
    &:hover, &:active {
      color: var(--site-clr-light);
      border: 1px solid var(--site-clr-light);
    }
  `

  return (
    <HollowBtn onClick={props.onClick}>
      {props.children}
    </HollowBtn>
  )
}
