import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Moment from 'react-moment'
import styled from 'styled-components'
import { HollowButton } from './HollowButton'
import { ModalContents, ModalOpenButton, ModalProvider } from './Modal'
import ModalGigDetails from './ModalGigDetails'

export const GigCard = ({ item, width }) => {

  const minWidth = 350;

  const cardImages = useStaticQuery(graphql`
      query {
        background: file(name: {eq: "card-bg"}) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              placeholder: DOMINANT_COLOR,
              quality: 100
            )
          }
        }
      }
  `)

  const Card = styled.button`
    all: unset;
    height: auto;
    min-width: 400px;
    max-width: ${width || 500}px;
    font-family: Poppins, Arial;
    margin: 12px;

    @media screen and (max-width: 768px) {
      min-width: ${minWidth}px;
    }

    .card-gig-venue {
      color: #229897;
      font-size: clamp(1.5rem, 1.4vw, 2rem); 
      // height: 125px;
      // display: table-cell;
      // vertical-align: middle;
    }

    .card-gig-date {
      color: #CDCDCD;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: clamp(1.2rem, 1vw, 1.4rem); 
    }

    @media screen and (min-width: 768px) {

      min-width: ${minWidth}px;

      .card-gig-venue {
        min-height: 100px;
      }
    }
  `

  const Header = styled.div`
    padding: 10px;
    background-color: var(--site-clr-dark-alt);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px dashed var(--site-clr-dark-accent);
    border-bottom: none;
  `

  const Img = styled.div`
    background-color: var(--site-clr-dark-alt);

    @media screen and (max-width: 768px) {
      height: 310px;
      overflow: hidden;
    }
  `

  const Footer = styled.div`
    padding: 15px;
    background-color: var(--site-clr-dark-alt);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px dashed var(--site-clr-dark-accent);
    border-top: none;
  `

  return (
    <>
      <Card>
        <Header>
          <div class="card-gig-venue">
            {item.place.name}
          </div>
          <div class="card-gig-date">
            <Moment format="ddd Do MMM YYYY @ h:mm a">
              {item.start_time}
            </Moment>
          </div>
        </Header>
        <Img>
          <GatsbyImage image={getImage(cardImages.background)} />
        </Img>
        <Footer>
          <ModalProvider>
            <ModalOpenButton>
              <HollowButton>More Info</HollowButton>
            </ModalOpenButton>
            <ModalContents>
              <ModalGigDetails item={item} />
            </ModalContents>
          </ModalProvider>
        </Footer>
      </Card>
    </>
  )
}
