import React from 'react';
import styled from 'styled-components';

const ModalBackground = ({ children, red, green, blue, alpha }) => {

  const Container = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(${red || 0}, ${green || 0}, ${blue || 0}, ${alpha || 0.80});
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: hidden;
  `

  return (
    <Container>
      {children}
    </Container>
  );
}

export default ModalBackground;
