import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { FourshoreButton } from './FourshoreButton'
import { HollowButton } from './HollowButton'
import { ModalDismissButton } from './Modal'
import ModalBackground from './ModalBackground'
import { Title } from './Title'

const ModalContent = styled.div`
  background-color: var(--site-clr-dark-alt);
  border: 1px dashed var(--site-clr-dark-accent);
  border-radius: 5px;
  color: var(--site-clr-light);
  height: auto;
  width: 80%;
  font-family: Poppins, Arial;
  font-size: clamp(1.2rem, 1.75vw, 2.5rem); 
  text-align: center;
  padding: 20px;
  margin-right: 15px;

  .menu-close-btn {
    margin-top: 30px;
  }
`

const ModalMenu = () => {

  const navigateToSection = (event) => {
    event.preventDefault();

    // close the modal window
    let container = document.getElementById('closeBtnContainer');
    container.querySelector('button').click();

    // delay scroll for a bit
    setTimeout(() => {
      // navigate to the section clicked on the menu
      const id = "nav-" + event.target.innerHTML.toLowerCase();
      const element = document.querySelector("#" + id);
      element.scrollIntoView();
    }, 300);
  }

  return ReactDOM.createPortal(
    <ModalBackground alpha={0.85}>
      <ModalContent>
        <div class="grid direction-column">
          <div class="col-12">
            <Title text="Fourshore" />
          </div>
          <div class="col-12">
            <FourshoreButton width="90%" onClick={navigateToSection}>GIGS</FourshoreButton>
          </div>
          <div class="col-12">
            <FourshoreButton width="90%" onClick={navigateToSection}>ABOUT</FourshoreButton>
          </div>
          <div class="col-12">
            <FourshoreButton width="90%" onClick={navigateToSection}>MEDIA</FourshoreButton>
          </div>
          <div class="col-12 menu-close-btn">
            <ModalDismissButton>
              <div id={'closeBtnContainer'}>
                <HollowButton paddingLeftRight={100} paddingTopBottom={12}>Close</HollowButton>
              </div>
            </ModalDismissButton>
          </div>
        </div>
      </ModalContent>
    </ModalBackground>
    , document.getElementById('portal'))
}

export default ModalMenu;
