import React, {
  cloneElement,
  createContext, useContext, useState
} from 'react';

const callAll = (...fns) => (...args) => fns.forEach((fn) => fn && fn(...args));

const ModalContext = createContext();

const ModalProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />;
}

const ModalDismissButton = ({ children: child }) => {
  const [, setIsOpen] = useContext(ModalContext);

  return cloneElement(child, {
    onClick: callAll(() => { 
      document.body.style.overflow = "auto";
      setIsOpen(false);
    }, child.props.onClick)
  });
}

const ModalOpenButton = ({ children: child }) => {
  const [, setIsOpen] = useContext(ModalContext);

  return cloneElement(child, {
    onClick: callAll(() => { 
      document.body.style.overflow = "hidden";
      setIsOpen(true);
    }, child.props.onClick)
  });
}

const ModalContents = (props) => {
  const [isOpen, setIsOpen] = useContext(ModalContext);

  if (!isOpen) {
    return null;
  }

  return (
    <div {...props}>
      {props.children}
    </div>
  );
}

export { ModalProvider, ModalDismissButton, ModalOpenButton, ModalContents };
