import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 1vh;
  margin-top: 0;
  font-family: Poppins, Arial;
  font-size: clamp(1rem, 1rem + 1.5vw, 2rem);
  font-weight: bold;
  text-align: center;

  .divider {
    width: 8%;
    height: 3px;
    background-color: #229897;
    margin: 0 auto;
    margin-bottom: 1vh;
  }
`

export const Title = (props) => {
  return (
    <Container>
      <div class="divider">&nbsp;</div>
      <div>{props.text}</div>
    </Container>
  )
}
