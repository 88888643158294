import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import crs from 'create-random-string'
import React from 'react'
import styled from 'styled-components'

library.add(faInstagram)

export const InstagramIcon = (props) => {

  const id = crs('(xX|7)');
  const defaultUrl = 'https://www.instagram.com/fourshoreband';

  const primaryColor = props.primaryColor || '#229897';
  const secondaryColor = props.secondaryColor || '#DCDCDC';

  const Container = styled.button`
    all: unset;
  `

  const IconOutterWrapper = styled.div`
    cursor: pointer;
    display: block;
    width: ${props.size}px; 
    height: ${props.size}px;
    background-color: ${primaryColor};
    border-radius: 50%;
    transition: ease background-color 500ms;
    border: 2px solid ${primaryColor};
    color: ${secondaryColor};
    text-align: center;
    font-size: ${props.size / 2.36}pt;

    &:hover, &:active {
      color: ${primaryColor};
      background-color: ${secondaryColor};
    }
  `

  const IconInnerWrapper = styled.div`
    padding-top: ${props.size / 4.5}px;
  `

  const visitSocial = (event) => {
    event.preventDefault();
    var widget = document.querySelector('#' + id);
    widget.querySelector('#insta-social-nav-link').click();
  }

  return (
    <Container id={id}>
      <a id="insta-social-nav-link" href={props.url || defaultUrl} target="_blank" rel="noreferrer"></a>
      <IconOutterWrapper onClick={visitSocial} data-tip="Instagram">
        <IconInnerWrapper>
          <FontAwesomeIcon icon={faInstagram} fixedWidth />
        </IconInnerWrapper>
      </IconOutterWrapper>
    </Container>
  )
}
