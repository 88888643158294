import React from 'react';
import styled from 'styled-components';

export const FourshoreButton = (props) => {

  const buttonWidth = props.width || '125px';
  const primaryColor = props.primaryColor || '#192938';
  const secondaryColor = props.secondaryColor || '#229897';

  const BaseBtn = styled.button`
    color: ${secondaryColor};
    background-color: ${primaryColor};
    border: 1px solid ${secondaryColor};
    padding: 12px 0px;
    border-radius: 4px; 
    outline: 0;
    font-family: 'Roboto', Arial;
    text-transform: uppercase;
    font-size: clamp(0.8rem, 0.8vw, 2rem);
    cursor: pointer;
    transition: ease background-color 500ms;
    text-align: center;
    width: ${buttonWidth};
  `

  const FourshoreBtn = styled(BaseBtn)`
    &:hover, &:active {
      color: ${primaryColor};
      border: 1px solid ${secondaryColor};
      background-color: ${secondaryColor};
    }
  `

  return (
    <FourshoreBtn onClick={props.onClick}>
      {props.children}
    </FourshoreBtn>
  )
}
