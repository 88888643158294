import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import styled from 'styled-components';
import { HollowButton } from './HollowButton';
import { ModalDismissButton } from './Modal';
import ModalBackground from './ModalBackground';

const ModalContent = styled.div`
  background-color: var(--site-clr-dark-alt);
  border: 1px dashed var(--site-clr-dark-accent);
  border-radius: 5px;
  color: var(--site-clr-light);
  height: auto;
  width: 45%;
  font-family: Poppins, Arial;
  font-size: clamp(1.2rem, 1.75vw, 2.5rem);
  
  @media screen and (max-width: 850px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    margin-right: 15px;
  }

  .modal-header {
    color: var(--site-clr-accent);
  }

  .gig-details-title {
    color: var(--site-clr-accent);
    text-align: center;
    vertical-align: middle;
  }

  .gig-details-date {
    font-size: clamp(1rem, 1vw, 2.5rem); 
    vertical-align: middle;
    text-align: center;
  }

  .gig-details-close-btn {
    text-align: center;
  }

  .gig-details-description {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
    font-size: clamp(1rem, 1vw, 2.5rem); 
    padding-left: 20px;
    padding-right: 20px;
  }

  .gig-details-info {
    font-size: clamp(1rem, 0.75vw, 2.5rem);
    margin-top: 20px;
    table {
      margin: 0 auto;
    }
  }

  .gig-details-info-label {
    color: var(--site-clr-accent);
    text-align: right;
    padding-right: 7px;
  }

  .gig-details-modal-footer {
    margin-top: 45px;
  }
`

const ModalGigDetails = ({ item }) => {
  return ReactDOM.createPortal(
    <ModalBackground alpha={0.85}>
      <ModalContent>
        <div class="grid">
          <div class="col-12">
            <div class="gig-details-title">
              {item.place.name}
            </div>
          </div>
          <div class="col-12 gig-details-description">
            {item.description}
          </div>
        </div>
        <div class="grid grid-bleed">
          <div class="col-12 gig-details-info">
            <table>
              <tr>
                <td class="gig-details-info-label">Date:</td>
                <td>
                  <Moment format="ddd Do MMM YYYY @ h:mm a">
                    {item.start_time}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td class="gig-details-info-label">Venue:</td>
                <td>{item.place.name}</td>
              </tr>
              <tr>
                <td class="gig-details-info-label">Street:</td>
                <td>{item.place.location.street}</td>
              </tr>
              <tr>
                <td class="gig-details-info-label">City:</td>
                <td>{item.place.location.city}</td>
              </tr>
              <tr>
                <td class="gig-details-info-label">State:</td>
                <td>{item.place.location.state}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="grid">
          <div class="col-12 gig-details-modal-footer">
            <ModalDismissButton>
              <div className='gig-details-close-btn'>
                <HollowButton>Close</HollowButton>
              </div>
            </ModalDismissButton>
          </div>
        </div>
      </ModalContent>
    </ModalBackground>
    , document.getElementById('portal'))
}

export default ModalGigDetails;
